import React, { Component } from 'react';
import Header from './header/header';
import SongList from './songlist/songlist';
import Footer from './footer/footer';
import './app.css';

class App extends Component {
  render() {
    return (
      <div className="app">
        <Header/>
        <SongList/>
        <Footer/>
      </div>
    );
  }
}

export default App;
