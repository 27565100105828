import React, { Component } from 'react';
import "./songlist.css";

class SongList extends Component {
    render() {
        var songs = require('../songs.js').songs;
        var songList = [];
        for (let song of songs) {
            songList.push(
                <div key={song.name}>
                    <hr />
                    <a className="anchor" id={song.name}>{song.name}</a>
                    <h3 className="song-name">{song.name}</h3>
                    <pre className="song-lyrics">{song.lyrics}</pre>
                </div>
            );
        }

        return songList;
    }
}

export default SongList;