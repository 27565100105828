import React, { Component } from 'react';
import "./footer.css";

const ABOUT = 'About';

class Footer extends Component {
    render() {
        return (
            <div>
                <hr id={ABOUT} />
                <footer className="app-footer">
                    <h3>{ABOUT}</h3>
                    <pre>
                        © Copyright 2012 - Richard Korn
                    </pre>
                    <pre>
                        Songs written and performed by Richard Korn
                    </pre>
                    <pre>
                        Recorded by Saman Khoujinian
                    </pre>
                    <pre>
                        Album Artwork by Daniella Tudela
                    </pre>
                </footer>
            </div>
        )
    }
}

export default Footer;