import React, { Component } from 'react';
import MediaQuery from 'react-responsive';
import './header.css';

const DIVIDER = '| ';
const ALBUM_TITLE = 'Colored Grey'
const ABOUT = 'About';
const RICHARD_KORN = 'Richard Korn';

class Header extends Component {
    render() {
        let songs = require('../songs.js').songs;
        let songNameList = [];
        for (let song of songs) {
            songNameList.push(
                <li key={song.name}>
                    <a href={"#" + song.name}>{song.name}</a>
                </li>
            );
        }

        let player =
            <iframe
                title="Embeded Album"
                className="embeded-album"
                src="https://bandcamp.com/EmbeddedPlayer/album=3032631160/size=large/bgcol=333333/linkcol=ffffff/minimal=true/transparent=true/"
                seamless
            >
                <a href="http://richardkorn.bandcamp.com/album/colored-grey">Colored Grey by Richard Korn</a>
            </iframe>

        let appHeaderFullSize =
            <div className="app-header-container">
                <div className="app-header">
                    {player}
                    <header>
                        <h1 className="app-title">
                            <div>{RICHARD_KORN}</div>
                            <div className="album-title">
                                {DIVIDER}
                                <a href="http://richardkorn.bandcamp.com/album/colored-grey"
                                    rel="noopener noreferrer" target="_blank">{ALBUM_TITLE}</a>
                            </div>
                        </h1>
                        <ul className="song-list">
                            {songNameList}
                            <li className="about">{DIVIDER}<a href={'#' + ABOUT}>{ABOUT}</a></li>
                        </ul>
                    </header>
                </div>
                <hr />
            </div>

        let appHeaderMobile =
            <div className="app-header-mobile">
                {player}
                <header>
                    <h1 className="app-title-mobile">
                        <div>{RICHARD_KORN}</div>
                        <div className="album-title">
                            {DIVIDER}
                            <a href="http://richardkorn.bandcamp.com/album/colored-grey"
                                rel="noopener noreferrer" target="_blank">{ALBUM_TITLE}</a>
                        </div>
                    </h1>
                </header>
            </div>

        return (
            <MediaQuery query="(min-device-width: 1024px)">
                {matches => matches ? (
                    <div className="app-header-dock"> {appHeaderFullSize} </div>
                ) : (
                    <div > {appHeaderMobile} </div>
                )}
            </MediaQuery>
        );
    }
}

export default Header;