// eslint-disable-next-line
module.exports = {
    songs : [{
name: 'New Orleans',
lyrics:
`I was headed down to New Orleans
It seemed the right place to stay
You were coming back from New Orleans
You were headed my way

And we could go away from here
For as long as you'd like
And we could go away from here
For as long as you'd like

I was head down to the south bound train
I caught you looking at me
You came over with a smile on your face
To tell me your story

And we could go away from here
For as long as you'd like
And we could go away from here
For as long as you'd like`
},
{
name: "It's Cold Outside",
lyrics:
`You can go and throw it all away
While the night and the sun clash in my eyes
You can go and throw it all away
In the morning I'll be just fine

It's cold outside and I don't mind
It's cold outside and I don't mind

I was waiting by a tree for the leaves to fall
You came over holding flowers wanting more to love
I was waiting by that tree for the leaves to fall
Withered pedals like the night in your open palms

It's cold outside and I don't mind
It's cold outside and I don't mind

I saw a leaf pass by one summer night
It was blue, it was green, and it made you sigh
I saw the moon pass by one winter night
It was yellow like the pedals we were passing by

It's cold outsiide and I don't mind
It's cold outsiide and I don't mind
It's cold outsiide and I don't mind
It's cold outsiide and I don't mind`
},
{
name: 'The Wind',
lyrics:
`I asked "What's wrong?"
She said "Nothing...
Except the wind
It blows all night
but never threw me
I want to
be just as free"

Softly she goes
Softly she goes
Softly she goes
Softly she goes

Don't you worry babe
It'll be alright
Don't you worry babe
It'll be alright

It's so easy to forget
It's so easy to forget

I asked "What's wrong?"
She said "Nothing...
Except the wind
It blows all night
but never threw me
I want to
be just as free"

Softly she goes
Softly she goes
Softly she goes
Softly she goes`
},
{
name: 'Stranded',
lyrics:
`You had me from hello
and I kept on telling you the reasons
But it's more for me to know
that you could be there when I need you

I asked you - in order to save myself
but your words have surely fallen
"Can we make this work?"
A simple "yes" and now I am broken

Don't leave me stranded here
 is all I think about most mornings
Don't leave me stranded here
 was on the tip of my tongue when I first saw you
Don't leave me stranded here
 is all I think about when I'm with you
Don't leave me stranded here
 was on the tip of my tongue when I kissed you`
},
{
name: 'Friends',
lyrics:
`I've been waiting for a while
to see how this might end
and now that I'm with you again
it was different to call you a friend

I've been waiting for a while
to see how you might feel
and after the night that we shared
it was different for you not to care

But that's the way life goes
I'm better of now I suppose
You were beautiful that day
I guess I wished you had stayed

I've been waiting for a while
to move away from here
and now that you have reappeared
I am ready to go on my own

I've been waiting for while
to hurt myself again
and now that I have my chance
I am ready to call you a friend
I am ready to call you a friend
I am ready to call you a friend`
}


]};
